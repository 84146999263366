<template>
	<div class="DeviceEventCfgList">
		
		<el-row >
			<el-button type="primary"  size="mini" @click="addEvent()">新增</el-button>
			<el-button v-if="selMode" type="primary"  size="mini" @click="closeEventCfgList()">关闭</el-button>
			<el-button type="primary"  size="mini" @click="refresh()">刷新</el-button>
		</el-row>
		
		<table v-if="plist && plist.length > 0" class="configItemTalbe" width="99%">
			<thead>
				<tr>
					<td v-if="!selMode">{{"ID"|i18n}}</td>
					<td>{{'产品名称'}}</td>
					<td>{{'设备名称'}}</td>
					<td>{{'事件归属'}}</td>
					<td>{{'事件类型'}}</td>
					<td v-if="!selMode">{{'cmdId'|i18n}}</td>
					<td>{{'status'|i18n}}</td>
					<td>{{'设备事件'|i18n}}</td>
					<td>{{'desc'|i18n}}</td>
					<td>{{'sext1'|i18n}}</td>
					<td v-if="!selMode">{{'clientId'|i18n}}</td>
					<td v-if="!selMode">{{'createdBy'|i18n}}</td>
					<td>{{"Operation"|i18n}}</td>
				</tr>
			</thead>
			<tr v-for="c in plist" :key="'h_'+c.id">
				<td v-if="!selMode">{{c.id}}</td>
				<td>{{prdMap[c.productId]}}</td>
				<td >{{myDeviceMap[c.srcDeviceId]}}</td>
				<td>{{$jm.IOT.JM_OP_SRC_LABELS[c.by-1]}}</td>
				<td>{{ getTypeLabel(c.eventCode) }}</td>
				<td v-if="!selMode">{{c.cmdId}}</td>
				<td>{{c.status?"启用":"禁用"}}</td>
				<td>{{c.direct2Device?"设备事件":"延伸事件"}}</td>
				<td class="descCol">{{c.desc}}</td>
				<td>{{c.sext1}}</td>
				<td v-if="!selMode">{{c.clientId}}</td>
				<td v-if="!selMode">{{c.createdBy}}</td>
				<td>
					<a v-if="selMode"  @click="selectEvent(c)">{{'Select'|i18n}}&nbsp;</a>
					<a @click="viewDetail(c)">{{'View'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="updateCmd(c)">{{'Update'|i18n}}&nbsp;</a>
					<a v-if="$jr.auth.updateAuth(c.createdBy)" @click="deleteAs(c)">{{'Delete'|i18n}}&nbsp;</a>
				</td>
			</tr>
		</table>

		<div v-if="isLogin && plist && plist.length > 0 && !fromOp" style="position:relative;text-align:center;">
			<Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
				show-elevator show-sizer show-total @on-change="curPageChange" @on-page-size-change="pageSizeChange"
				:page-size-opts="[10, 30, 60,100]"></Page>
		</div>

		<div v-if="!isLogin">
			No permission!
		</div>

		<div v-if="!plist || plist.length == 0">
			No data!
		</div>
		
		<Drawer ref="asInfo"  v-model="asDrawer.drawerStatus" :closable="false" placement="right" 
			:transfer="false"
			:styles="asDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" 
			width="50" :mask-closable="false" :mask="false" :z-index="99999"
			style="z-index: 33333;">
			
			<el-row>
				<el-button size="mini" @click="asDrawer.drawerStatus = false">关闭</el-button>
				<el-button  :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">确定</el-button>
			</el-row>
			
			<el-row>
				<el-col>事件归属类型</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.by" :disabled="model==3">
						<el-option v-for="(v,k) in $jm.IOT.JM_OP_SRC" :key="v" :value="v" 
						:label="$jm.IOT.JM_OP_SRC_LABELS[v-1]"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<!-- 在主设备上增加从设备指令，这里选择从设备 -->
			<el-row v-if="as.by==$jm.IOT.JM_OP_SRC.DEVICE">
				<el-col>事件设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.srcDeviceId" 
						:disabled="model==3 || by==$jm.IOT.JM_OP_SRC.DEVICE">
						<el-option v-for="(v,k) in myDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row v-if="as.by==$jm.IOT.JM_OP_SRC.PRODUCT">
				<el-col>事件所属产品</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.productId" :disabled="model==3">
						<el-option v-for="(val,key) in prdMap" :key="key" :value="key" :label="val"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>触发事件类型</el-col>
				<el-col>
					<el-select style="width:100%" v-model="as.eventCode" :disabled="model==3">
						<el-option v-for="(v,k) in eventCodeTypes" :key="v.key" :value="v.attr0" 
						:label="v.name"></el-option>
					</el-select>
				</el-col>
			</el-row>
			<el-row>
				<el-col>{{cmdNameLabel}}</el-col>
				<el-col><el-input v-model="as.cmdId"
				:disabled="model==3 || as.eventCode==$jm.IOT.EVENT_CODES.ASR"/></el-col>
			</el-row>
			
			<!--
			#define RISING    0x01
			#define FALLING   0x02
			#define CHANGE    0x03
			<el-row v-if="as.eventCode==8">
				<el-col>中断沿</el-col>
				<el-col>
					<el-select style="width:100%" v-model="intType" :disabled="model==3">
						<el-option key="1" :value="1"  label="上升沿"></el-option>
						<el-option key="2" :value="2"  label="下降沿"></el-option>
						<el-option key="3" :value="3"  label="双边沿"></el-option>
					</el-select>
				</el-col>
			</el-row>
			-->
			
			 <el-row>
				<el-col>描述</el-col>
				<el-col><el-input v-model="as.desc" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="as.status" :disabled="model==3" placeholder="请选择">
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			 </el-row>
			 
			 <el-row>
				<el-col>是否设备事件</el-col>
				<el-select style="width:100%" v-model="as.direct2Device" :disabled="model==3" placeholder="请选择">
					<el-option :key="true" :value="true" label="设备事件"></el-option>
					<el-option :key="false" :value="false" label="延伸事件"></el-option>
				</el-select>
			 </el-row>
			
			<el-row v-if="sext1Label">
				<el-col>{{sext1Label}}</el-col>
				<el-col><el-input v-model="as.sext1" :disabled="model==3" /></el-col>
			</el-row>
			
			 <el-row v-if="sext2Label">
				<el-col >{{sext2Label}}</el-col>
				<el-col><el-input v-model="as.sext2" :disabled="model==3" /></el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>ID</el-col>
				<el-col><el-input v-model="as.id" :disabled="true" /></el-col>
			 </el-row>
			
			 <el-row v-if="model==1">
			 	<el-col :span="24">
					<el-button type="primary"  size="mini" @click="addParam()">新增参数</el-button>
			 	</el-col>
			 </el-row>
			 
			 <el-row v-if="model==1">
			 	<el-col :span="24">
			 		{{selectEventCodeVal?selectEventCodeVal.desc:""}}
			 	</el-col>
			 </el-row>
			 
			 <table v-if="as.attrs && as.attrs.length > 0" class="configItemTalbe" width="99%">
			     <thead>
			        <td>{{'name'|i18n}}</td> <td>{{'label'|i18n}}</td> <td>{{'type'|i18n}}</td>
			 		<td>{{'val'|i18n}}</td><td>{{'desc'|i18n}}</td>
					<td>{{'maxVal'|i18n}}</td><td>{{'eventCmdId'|i18n}}</td>
			         <td>{{"Operation"|i18n}}</td></tr>
			     </thead>
			     <tr v-for="c in as.attrs" :key="'h_'+c.name">
			 		  <td>{{c.name}}</td> <td>{{c.label}}</td> <td>{{$jr.cons.PREFIX_TYPE_2DESC[c.type]}}</td>
					  <td>{{c.val}}</td><td class="descCol">{{c.desc}}</td><td>{{c.maxVal}}</td>
						<td>{{c.eventCmdId}}</td>
			         <td>
			            <a v-if="model==1" @click="viewParam(c)">{{'View'|i18n}}</a>&nbsp;
			            <a v-if="model==1" @click="updateParam(c)">{{'Update'|i18n}}</a>&nbsp;
			            <a v-if="model==1" @click="deleteParam(c)">{{'Delete'|i18n}}</a>
			         </td>
			     </tr>
			 </table> 
		</Drawer>
		
		<Drawer ref="defInfo"  v-model="defInfoDrawer.drawerStatus" :closable="false" placement="right" :transfer="true"
			:draggable="true" :scrollable="true" width="50" :mask-closable="false" :mask="true">
			<el-row>
				<el-col :span="6">{{"Name"|i18n}}</el-col>
				<el-col><el-input v-model="p.name" :disabled="pmodel!=2" /></el-col>
			</el-row>
			<el-row>
				<el-col :span="6">{{"label"|i18n}}</el-col>
				<el-col>
					<el-input v-model="p.label" :disabled="pmodel==3" />
				</el-col>
			</el-row>
			 <el-row>
				<el-col :span="6">{{"val"|i18n}}</el-col>
				<el-col><el-input v-model="p.val" :disabled="pmodel==3" /></el-col>
			 </el-row>
			 <el-row>
				<el-col :span="6">{{"maxVal"|i18n}}</el-col>
				<el-col><el-input v-model="p.maxVal" :disabled="pmodel==3" /></el-col>
			 </el-row>
			 <el-row>
				<el-col :span="6">{{"eventCmdId"|i18n}}</el-col>
				<el-col><el-input v-model="p.eventCmdId" :disabled="pmodel==3" /></el-col>
			 </el-row>
			 <el-row>
				<el-col :span="6">{{"Type"|i18n}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="p.type" :disabled="pmodel==3">
						<el-option v-for="(v,k) in $jr.cons.PREFIX_TYPE_2DESC"
							:key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			 </el-row>
			 <el-row>
				<el-col :span="6">{{"Desc"|i18n}}</el-col>
				<el-col><el-input type="textarea" autosize v-model="p.desc" :disabled="pmodel==3"/></el-col>
			 </el-row>
			 <el-row>
				<el-button size="mini" @click="defInfoDrawer.drawerStatus = false">取消</el-button>
				<el-button  :disabled="pmodel==3" size="mini" type="primary" @click="doAddOrUpdateEventArg">确定</el-button>
			 </el-row>
		</Drawer>
		
		<!-- 弹出查询页面 -->
		<div v-if="isLogin"  :style="queryDrawer.drawerBtnStyle" class="drawerJinvokeBtnStatu" @mouseenter="openQueryDrawer()"></div>
		<!-- 数据查询开始-->
		<Drawer v-if="isLogin"   v-model="queryDrawer.drawerStatus" :closable="false" placement="left" :transfer="true"
		         :draggable="true" :scrollable="true" width="25">
				 
			 <el-row>
				<el-col>事件设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="queryParams.ps.srcDeviceId">
						<el-option  :key="-1" :value="-1" label="全部"></el-option>
						<el-option v-for="(v,k) in myDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			 </el-row>
			 
			 <el-row>
			 	<el-col>触发事件类型</el-col>
			 	<el-col>
			 		<el-select style="width:100%" v-model="queryParams.ps.eventCode">
						<el-option  :key="-1" :value="-1" label="全部"></el-option>
			 			<el-option v-for="(v,k) in eventCodeTypes" :key="v.key" :value="v.attr0" 
			 			:label="v.name"></el-option>
			 		</el-select>
			 	</el-col>
			 </el-row>
			 
			 <el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="queryParams.ps.status" placeholder="请选择">
					<el-option  :key="-1" :value="-1" label="全部"></el-option>
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			 </el-row>
			 
			 <el-row>
				<el-button size="mini" @click="queryDrawer.drawerStatus = false">取消</el-button>
				<el-button size="mini" type="primary" @click="doQuery">{{"Query"|i18n}}</el-button>
			 </el-row>
				 
		</Drawer><!-- 数据查询结束-->
		
	</div>
</template>

<script>
	
	import jmiot from "../../rpcservice/jm.js"
	
	const cid = 'DeviceEventCfgList';

	export default {
		name: cid,
		components: {},
		props: {
			selMode: {
				type: Boolean,
				default: false,
			},
		},
		
		data() {
			return {
				as: {},
				model: 3,

				errorMsg: '',
				isLogin: true,
				plist: [],

				cmdNameLabel:"语音命令",
				sext1Label:null,
				sext2Label:null,
				myDeviceMap: {},
				
				funDef: {},
				cmd: {},
				fromOp: false,
				dev:{},
				by:jmiot.IOT.JM_OP_SRC.DEVICE,
				
				prdMap:{},
				eventCodeTypes:[],
				selectEventCodeVal:{},
				
				//参数
				grpList:[],
				p:{},
				pmodel:3,//参数操作模式，查看，新增，更新
				
				intType:2, //引脚中断沿类型

				queryParams: {
					size: 30,
					curPage: 1,
					ps: {
						status:"-1",
						srcDeviceId:"-1",
						eventCode:"-1",
						selectType: 0
					}
				},
				totalNum: 0,
				
				//参数增加更新
				defInfoDrawer: {
				    drawerStatus : false,
				    drawerBtnStyle : {left:'0px',zindex:1000},
				},

				asDrawer: {
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px'
					},
				},
				
				queryDrawer: {
				    drawerStatus:false,
				    drawerBtnStyle:{left:'0px',zindex:1000},
				},
			}
		},

		watch:{
            "as.eventCode":function(eventCode){
				this.sext1Label = null
				this.sext2Label = null

				if(eventCode == this.$jm.IOT.EVENT_CODES.ASR) {
								    this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"ID（提交后由后台自动分配）"
									this.sext1Label = "语音命令"
									this.sext2Label = "拼音命令"
				} else if(eventCode == this.$jm.IOT.EVENT_CODES.IR){
									this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"键码值"
				} else if(eventCode == this.$jm.IOT.EVENT_CODES.KEY){
								   this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"键码"
				}else if(eventCode == this.$jm.IOT.EVENT_CODES.MQ){
								   this.cmdNameLabel = this.$jm.IOT.getEventCodeLabel(eventCode)+"类型码"
				} else {
					let ecv = this.eventCodeTypes.find(e => {return e.attr0==eventCode})
					if(ecv && ecv.attr1) {
						this.selectEventCodeVal = ecv
						this.cmdNameLabel = ecv.attr1
					} else {
						this.cmdNameLabel = "命令ID"
					}
				}
				
            }
        },
		
		methods: {
		
			
			openQueryDrawer() {
			    this.queryDrawer.drawerStatus = true
			    this.queryDrawer.drawerBtnStyle.zindex = 10000
			    this.queryDrawer.drawerBtnStyle.left = '0px'
			},
			
			addParam(){
				this.pmodel = 2;
				this.p = {type:this.$jr.cons.PREFIX_TYPE_STRINGG};
				this.errorMsg = '';
				//this.p.ptypeLabel = this.$jr.cons.PREFIX_TYPE_2DESC[this.p.type]
				this.p.type = parseInt(this.p.type)
				this.defInfoDrawer.drawerStatus = true;	
			},
			
			viewParam(c){
				this.pmodel = 3;
				this.p = c;
				this.p.type += ""
				//this.p.ptypeLabel = this.$jr.cons.PREFIX_TYPE_2DESC[this.p.type]
				//this.p.type = parseInt(this.p.type)
				this.defInfoDrawer.drawerStatus = true;
			},
			
			updateParam(c){
				this.pmodel = 1;
				this.p = c
				this.errorMsg = '';
				this.p.type += ""
				//this.p.ptypeLabel = this.$jr.cons.PREFIX_TYPE_2DESC[this.p.type]
				//this.p.type = parseInt(this.p.type)
				this.defInfoDrawer.drawerStatus = true
			},
			
			deleteParam(p) {
				
				this.$jr.rpc.invokeByCode(275127263, [this.as.id, 4, p])
				.then((resp) => {
					if (resp.code == 0) {
						for(let i = 0; i < this.as.attrs.length; i++) {
							if(this.as.attrs[i].name == p.name) {
								this.as.attrs.splice(i,1);
								this.p = {}
								break;
							}
						}
						this.$refs.asInfo.$forceUpdate()
					} else {
						this.$notify.error({
							title: '错误',
							message: resp.msg || "未知错误"
							});
					}
				}).catch((err) => {
					this.$notify.error({
						title: '错误',
						message: JOSN.stringify(err)
					});
				});
			},
			
			doAddOrUpdateEventArg() {
				if(!this.model) {
					this.$notify.error({
					 title: '错误',
						message: '非更新模式'
					});
					return false
				}
				
				if(!this.as.id) {
					//新增状态下，不需调用此方法存存参数
					return;
				}
				
				if(!(this.pmodel == 1 || this.pmodel == 2)) {
					this.$notify.error({
					 title: '错误',
						message: '非更新模式,无需单独保存参数'
					});
					return false
					return;
				}
			
				if(!this.p.name) {
					this.$notify.error({
					 title: '错误',
						message: '参数名称不能为空'
					});
					return false
				}
				
				if (!this.p.type) {
					this.$notify.error({
					 title: '错误',
						message: '类型不能为空'
					});
					return false
				}
							
				if (!this.p.desc) {
					this.$notify.error({
						title: '错误',
						message: '参数描述不能为空'
					});
					return false
				}
				//console.log("Update suc=============");
				this.$jr.rpc.invokeByCode(275127263, [this.as.id, this.pmodel, this.p])
				.then((resp) => {
					this.defInfoDrawer.drawerStatus = false;
					if (resp.code == 0 && resp.data) {
						if(this.pmodel == 2) {
							//新增
							this.as.attrs.push(this.p)
							this.$refs.asInfo.$forceUpdate()
							//console.log("Update suc*********************");
						}
					} else {
						this.$notify.error({
							title: '错误',
							message: resp.msg || "未知错误"
							});
					}
				}).catch((err) => {
					this.$notify.error({
						title: '错误',
						message: JOSN.stringify(err)
					});
				});
				
			},
			
			getTypeLabel(ec) {
				let ecv = this.eventCodeTypes.find(e=>parseInt(e.attr0.toString())==ec);
				if(ecv && ecv.name.length > 0) {
					return ecv.name
				}else {
					return this.$jm.IOT.getEventCodeLabel(ec)
				}
			},
			
			selectEvent(c){
				if(this.selMode) {
					console.log("selectEvent",c)
					 this.$emit('onSeletEvent', c)
				}
			},
			
			closeEventCfgList(){
				if(this.selMode) {
					 this.$emit('onClose')
				}
			},
			
			async loadMasterDevice(){
				
				let loaded = false;
				for(let k in this.myDeviceMap) {
					loaded = true;
					break;
				}
				
				if(loaded) {
					return
				}
				
				let r = await this.$jr.rpc.invokeByCode(-2138649681, [this.$jm.IOT.DEV_ROLE.ALL])
				console.log("Master Data", JSON.stringify(r))
				if(r.code != 0) {
					this.$notify.error({title: '提示',message: r.msg || '加载主设备数据错误'})
					return
				}
				
				if(!r.data || r.data.length == 0) {
					map["0"] = "" //防止重复加载无效请求
					console.log("无主设备数据")
					return
				}
				this.myDeviceMap = r.data
			},
			
			closeDrawer() {
				this.$parent.$parent.closeAsDrawer()
			},

			resetData() {
				this.as = {eventCode: this.$jm.IOT.EVENT_CODES.ASR,
					status:true, by: this.by}
			},

			viewDetail(as) {
				this.model = 3
				this.as = as
				this.as.eventCode +="" 
				this.as.productId += ""
				//this.intType
				/*
				if(as.eventCode == 8 && as.attrs && as.attrs.length > 0) {
					let at = as.attrs.find(e=> return e.name=='intType')
					if(at) {
						this.intType = parseInt(at.val)
					}
				}
				*/
				
				//this.as.status+=""
				//this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			async addEvent(def, cmd, by, dev) {
				this.funDef = def
				this.cmd = cmd
				this.by = by
				this.dev = dev
				
				if(cmd) {
					this.fromOp = true
				}
				
				//console.log("by="+by);
				//console.log("dev",this.dev);
				//console.log("fromOp="+this.fromOp);
				
				this.model = 2
				this.resetData()
				if(this.fromOp) {
					this.as.opId = this.cmd.id
					if(this.by == this.$jm.IOT.JM_OP_SRC.DEVICE) {
						this.as.srcDeviceId = this.cmd.deviceId
						this.as.clientId = this.dev.srcClientId
						this.myDeviceMap[this.cmd.deviceId] = this.dev.name
					} else {
						this.loadMasterDevice()	
					}
				} else {
					await this.loadMasterDevice()
				}
				
				this.asDrawer.drawerBtnStyle.zindex = 99999
				this.asDrawer.drawerStatus = true
			},

			updateCmd(as) {
				this.model = 1;
				this.as = as
				this.as.productId += ""
				this.as.eventCode +="" 
				
				/*
				if(as.eventCode == 8 && as.attrs && as.attrs.length > 0) {
					let at = as.attrs.find(e=> return e.name=='intType')
					if(at) {
						this.intType = parseInt(at.val)
					}
				}
				*/
			   
				//this.as.status=""
				//this.resetData()
				//this.asDrawer.drawerBtnStyle.zindex = 99
				this.asDrawer.drawerStatus = true;
			},

			async deleteAs(c) {
				
				this.$confirm('此操作将永久删除当前数据，是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(async () => {
					let r = await this.$jr.rpc.invokeByCode(-1067459898, [c.id])
					if (r.code == 0) {
						let idx = this.plist.findIndex(e => e.id == c.id)
						if (idx >= 0) {
							this.plist.splice(idx, 1)
						}
						this.$notify.info({
							title: '提示',
							message: "删除成功"
						})
					} else {
						this.$notify.error({
							title: '提示',
							message: r.msg || "删除失败"
						})
					}
				}).catch(() => {
				});
			},

			doQuery() {
				this.queryParams.curPage = 1
				this.refresh();
			},

			doAddOrUpdateParam() {
				
				if(!(this.as.by == this.$jm.IOT.JM_OP_SRC.DEVICE ||
					this.as.by == this.$jm.IOT.JM_OP_SRC.PRODUCT) ) {
					this.$notify.error({
						title: '提示',
						message: "事件归属无效"
					});
					return;
				}

				if(this.as.by == this.$jm.IOT.JM_OP_SRC.DEVICE && !this.as.srcDeviceId) {
					this.$notify.error({
						title: '提示',
						message: "需选择一个触发此事件的设备"
					});
					return;
				}
				
				if(this.as.by == this.$jm.IOT.JM_OP_SRC.PRODUCT && !this.as.productId) {
					this.$notify.error({
						title: '提示',
						message: "产品类型无效"
					});
					return;
				}

				if (!this.as.eventCode) {
					this.$notify.error({
						title: '提示',
						message: "事件类型不能为空"
					});
					return;
				}

				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.IR) {
					if (this.as.cmdId<=0) {
						this.$notify.error({
							title: '提示',
							message: "事件类型不能为空"
						});
						return;
					}
				}
				
				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.KEY) {
					if (this.as.cmdId<0) {
						this.$notify.error({
							title: '提示',
							message: "按键码不能为空"
						});
						return;
					}
				}
				
				if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.ASR 
					&& !this.as.sext1) {
					this.$notify.error({
						title: '提示',
						message: "语音命令词为空"
					});
					suc.success(r);
				}

				this.as.eventCode = parseInt(this.as.eventCode)
				console.log(this.as);

				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(1053300880, [this.as])
					.then((resp) => {
						this.refresh();
						if (resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "更新成功"
							});
							this.asDrawer.drawerStatus = false;
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							});
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						});
					});
				} else if (this.model == 2) {
					//add
					if(this.as.eventCode==this.$jm.IOT.EVENT_CODES.INT) {
						this.as.attrs = [{name:"intT", type:-119, val:"2",label:"中断沿", desc:"中断沿类型"}]
					}
					
					this.$jr.rpc.invokeByCode(-371384652, [this.as])
					.then((resp) => {
						if(resp.code == 0) {
							this.$notify.info({
								title: '提示',
								message: "保存成功"
							});
							this.asDrawer.drawerStatus = false;
							//this.refresh()
							this.plist.push(resp.data)
							if(this.fromOp) {
								 this.$emit('onAddSuccess', resp.data)
							}
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg || "未知错误"
							});
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: JOSN.stringify(err)
						});
					});
				}

			},

			async getPrdMap() {
				//产品的KEYVAlue集合
				let r = await this.$jr.rpc.invokeByCode(-414142670, [])
				console.log("getPrdMap result: ",r)
				if(r.code != 0) {
					 this.$notify.error({title: '提示',message: r.msg});
					return
				}
				let pm = {}
				for(let k in r.data) {
					pm[k+''] = r.data[k]
				}
				this.prdMap = pm
				console.log(this.prdMap)
			},
			
			curPageChange(curPage) {
				this.queryParams.curPage = curPage
				this.refresh();
			},

			pageSizeChange(pageSize) {
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
				this.refresh();
			},
			
			loadEventListByOpId() {
				this.$jr.rpc.invokeByCode(332762450, [this.cmd.id])
				.then((resp) => {
					if (resp.code == 0) {
						console.log(resp)
						this.plist = resp.data
						this.totalNum = resp.total
					} else {
						this.plist = []
						this.totalNum = 0
						this.$notify.error({ title: '提示',message: r.msg })
					}
				}).catch((err) => {
					window.console.log(err)
				});
			},

			async refresh() {
				let self = this;

				this.isLogin = this.$jr.auth.isLogin();
				if (this.isLogin) {
					
					console.log("refresh");
					await this.loadMasterDevice();
					
					if(this.fromOp) {
						//从操作页面跳过来，只查询操作相关联的事件
						this.loadEventListByOpId()
						return
					}
					
					let params = this.getQueryConditions();
					let self = this;

					//listActiveSources
					this.$jr.rpc.invokeByCode(415654022, [params])
						.then((resp) => {
							if(this.queryParams.ps.eventCode) {
								this.queryParams.ps.eventCode += "";
							}
							
							if (resp.code == 0) {
								if (resp.total == 0) {
									this.plist = [];
									this.totalNum = 0;
									this.$notify.info({
										title: '提示',
										message: "查无数据"
									});
								} else {
									console.log(resp)
									this.plist = resp.data;
									this.totalNum = resp.total;
								}
							} else {
								this.plist = [];
								this.totalNum = 0;
								this.$notify.error({
									title: '提示',
									message: r.msg
								});
							}
						}).catch((err) => {
							window.console.log(err);
						});
				} else {
					self.roleList = [];
				}
			},

			getQueryConditions() {
				//this.queryParams.ps.masterDeviceId = this.dev.deviceId
				
				for(let k in this.queryParams.ps) {
					if(this.queryParams.ps[k]=='-1' || this.queryParams.ps[k]==-1) {
						this.queryParams.ps[k] = null;
					}
				}
				
				if(this.fromOp) {
					this.queryParams.ps.opId = this.cmd.id
				}
				
				if(this.queryParams.ps.eventCode) {
					this.queryParams.ps.eventCode = parseInt(this.queryParams.ps.eventCode)
				}
				
				return this.queryParams;
			}
			
	},

	async mounted() {
			//this.$el.style.minHeight = (document.body.clientHeight - 67) + 'px';
			this.$jr.auth.addActListener(this.refresh);
			//this.refresh();
			await this.getPrdMap();
			
			let r = await this.$jr.api.getDataType("iot_eventCode")
			if(r.code == 0) {
				this.eventCodeTypes = r.data
			}
				
			let self = this;
			this.$bus.$emit("editorOpen", {
				"editorId": cid,
				"menus": [{
						name: "REFRESH",
						label: "Refresh Event",
						icon: "ios-cog",
						call: self.refresh
					},
					{
						name: "Add",
						label: "Add Event",
						icon: "ios-cog",
						call: self.addEvent
					},
				]
			});

			let ec = function() {
				this.$jr.auth.removeActListener(cid);
				this.$off('editorClosed', ec);
			}

			this.$bus.$on('editorClosed', ec);
		},

		beforeDestroy() {
			this.$jr.auth.removeActListener(cid);
		},

	}
</script>

<style>
	.DeviceEventCfgList {
		border-top: 1px dotted lightgray;
		margin-top: 6px;
		padding-top: 10px;
		text-align: left;
	}

	.title {
		font-weight: bold;
		font-size: 17px;
	}

	.valCol {
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
</style>